exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-index-jsx": () => import("./../../../src/pages/admin/index.jsx" /* webpackChunkName: "component---src-pages-admin-index-jsx" */),
  "component---src-pages-admin-login-jsx": () => import("./../../../src/pages/admin/login.jsx" /* webpackChunkName: "component---src-pages-admin-login-jsx" */),
  "component---src-pages-configure-index-jsx": () => import("./../../../src/pages/configure/index.jsx" /* webpackChunkName: "component---src-pages-configure-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jak-zamawiac-jsx": () => import("./../../../src/pages/jak-zamawiac.jsx" /* webpackChunkName: "component---src-pages-jak-zamawiac-jsx" */),
  "component---src-pages-katalog-jsx": () => import("./../../../src/pages/katalog.jsx" /* webpackChunkName: "component---src-pages-katalog-jsx" */),
  "component---src-pages-polityka-prywatnosci-jsx": () => import("./../../../src/pages/polityka-prywatnosci.jsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-jsx" */),
  "component---src-pages-prywatnosc-dostawcy-uslug-jsx": () => import("./../../../src/pages/prywatnosc-dostawcy-uslug.jsx" /* webpackChunkName: "component---src-pages-prywatnosc-dostawcy-uslug-jsx" */),
  "component---src-pages-regulamin-jsx": () => import("./../../../src/pages/regulamin.jsx" /* webpackChunkName: "component---src-pages-regulamin-jsx" */),
  "component---src-pages-wysylka-jsx": () => import("./../../../src/pages/wysylka.jsx" /* webpackChunkName: "component---src-pages-wysylka-jsx" */),
  "component---src-templates-guardrail-details-jsx": () => import("./../../../src/templates/guardrail-details.jsx" /* webpackChunkName: "component---src-templates-guardrail-details-jsx" */)
}

